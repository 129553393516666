.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 260px;
  background: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  overflow-y: auto;
  transition: all 0.3s ease;
}

/* Estilização da barra de rolagem */
.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #e2e8f0;
  border-radius: 10px;
}

.sidebar-logo {
  padding: 1rem;
  border-bottom: 1px solid #f1f5f9;
}

.sidebar-logo img {
  width: 160px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.sidebar-menu {
  padding: 0.75rem;
}

.menu-section {
  margin-bottom: 0.75rem;
}

.menu-section-title {
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #13465A;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.25rem;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #13465A;
  text-decoration: none;
  border-radius: 0.4rem;
  transition: all 0.2s ease;
  margin-bottom: 0.2rem;
}

.menu-item:hover {
  background: linear-gradient(135deg, rgba(19, 70, 90, 0.08) 0%, rgba(19, 70, 90, 0.12) 100%);
  color: #13465A;
}

.menu-item.active {
  background: linear-gradient(135deg, rgba(19, 70, 90, 0.12) 0%, rgba(19, 70, 90, 0.18) 100%);
  color: #13465A;
  font-weight: 600;
}

.menu-item svg {
  color: #13465A;
  opacity: 0.8;
}

.menu-item:hover svg,
.menu-item.active svg {
  opacity: 1;
}

.menu-item i {
  width: 1.5rem;
  margin-right: 0.75rem;
  font-size: 1.1rem;
}

.menu-item span {
  font-size: 0.875rem;
  font-weight: 500;
}

/* Layout ajustado para o conteúdo principal */
.main-content {
  margin-left: 260px;
  padding: 4.5rem 1.5rem 0rem;
  background: #f8fafc;
  transition: all 0.3s ease;
}

/* Mobile Toggle Button */
.mobile-toggle {
  display: none;
  position: fixed;
  top: 12px;
  left: 15px;
  z-index: 1200;
  background: #13465A;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile-toggle:hover {
  background: #1a5b78;
}

/* Overlay para quando o sidebar estiver aberto no mobile */
.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Responsividade */
@media (max-width: 1024px) {
  .mobile-toggle {
    display: block;
  }

  .sidebar {
    transform: translateX(-100%);
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  }
  
  .sidebar.open {
    transform: translateX(0);
  }

  .sidebar-overlay.show {
    display: block;
  }
  
  .main-content {
    margin-left: 0;
    padding-top: 5rem;
  }
}

/* Ajuste para o header quando o sidebar estiver fechado */
@media (max-width: 1024px) {
  .header {
    left: 0;
    padding-left: 60px;
  }
} 