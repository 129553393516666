.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 260px;
  height: 60px;
  background: linear-gradient(135deg, #13465A 0%, #1a5b78 100%);
  box-shadow: 0 2px 8px rgba(19, 70, 90, 0.15);
  z-index: 999;
  transition: all 0.3s ease;
}

.navbar {
  height: 100%;
  padding: 0 1.5rem;
}

.navbar-custom {
  width: 100%;
}

/* Ajustes nas cores do texto e ícones no header */
.header .text-dark {
  color: #ffffff !important;
}

.header .vr {
  background-color: rgba(255, 255, 255, 0.2);
}

.header .bi-person-circle {
  color: #ffffff;
}

.header .dropdown-toggle:hover {
  opacity: 0.9;
}

/* Ajustes para mobile */
@media (max-width: 1024px) {
  .header {
    left: 0;
  }
} 