.page-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #f8fafc;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.page-full form {
  flex: 1;
  padding: 2rem;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.header-page-full {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: #13465A;
  font-size: 1.5rem;
  cursor: pointer;
}

.header-page-full:hover {
  opacity: 0.8;
}

.footer-page-full {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #454545;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1101;
}

.footer-client {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
}

.button-large.btn-light {
  background: #FFFFFF;
  color: #13465A;
  border: none;
}

.button-large.btn-light:hover {
  background: #e9e9e9;
  color: #13465A;
}

.button-large.text-white {
  background: transparent;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.button-large.text-white:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.5);
}

.button-large {
  min-width: 160px;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-large:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.page-full::-webkit-scrollbar,
.page-full form::-webkit-scrollbar {
  width: 8px;
}

.page-full::-webkit-scrollbar-track,
.page-full form::-webkit-scrollbar-track {
  background: transparent;
}

.page-full::-webkit-scrollbar-thumb,
.page-full form::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

.page-full::-webkit-scrollbar-thumb:hover,
.page-full form::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

.agendamentos-section {
  margin-top: 2rem;
  height: 100%;
}

.agendamentos-container {
  display: flex;
  gap: 2rem;
  padding: 1rem 0rem 1rem 0rem;
  min-height: 360px;
}

.calendar-container {
  width: 300px;
  min-width: 300px;
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(19, 70, 90, 0.05);
}

.table-container {
  flex: 1;
  overflow-x: auto;
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(19, 70, 90, 0.05);
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  height: 100%;
  min-height: 300px;
  color: #64748b;
}

.empty-state-icon {
  font-size: 3rem;
  color: #13465A;
  margin-bottom: 1rem;
  opacity: 0.5;
}

.empty-state-text {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #475569;
}

.empty-state-subtext {
  font-size: 0.9rem;
  color: #64748b;
  max-width: 300px;
  margin: 0 auto;
}

.flatpickr-calendar {
  box-shadow: none !important;
  width: 100% !important;
}

.flatpickr-day.today {
  border-color: #13465A !important;
  color: #13465A  !important;
}

.flatpickr-day.selected {
  background: #13465A !important;
  border-color: #13465A !important;
  color: #fafeff  !important;
}

@media (max-width: 1200px) {
  .agendamentos-container {
    flex-direction: column;
    padding: 1rem;
    min-height: auto;
  }

  .calendar-container {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  .table-container {
    width: 100%;
    margin-top: 1.5rem;
    min-height: 300px;
  }
}

@media (max-width: 768px) {
  .page-full form {
    padding: 1rem;
  }

  .footer-page-full {
    padding: 0 1rem;
  }

  .button-large {
    min-width: 120px;
    padding: 0.5rem 1rem;
  }
  
  .footer-client {
    gap: 0.5rem;
  }

  .agendamentos-section {
    margin-top: 1rem;
  }

  .agendamentos-container {
    padding: 0.5rem;
  }

  .empty-state {
    padding: 1rem;
    min-height: 200px;
  }

  .empty-state-icon {
    font-size: 2.5rem;
  }

  .empty-state-text {
    font-size: 1rem;
  }
} 