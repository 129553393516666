.login-container {
  min-height: 100vh;
  display: flex;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
}

.login-content {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 2rem;
}

.login-form-container {
  flex: 1;
  max-width: 450px;
  margin: auto;
  background: white;
  padding: 2.5rem;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}

.login-illustration {
  flex: 1;
  display: none;
  padding: 2rem;
}

.login-logo {
  width: 150px;
  height: auto;
  margin-bottom: 2rem;
}

.login-title {
  color: #2d3748;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
}

.form-floating {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-floating input {
  height: 3.5rem;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  background: transparent;
}

.form-floating input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
  outline: none;
}

.form-floating label {
  position: absolute;
  top: 0;
  left: 0.75rem;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  color: #718096;
  background: transparent;
  z-index: 1;
}

.form-floating input:focus ~ label, .form-floating input:not(:placeholder-shown) ~ label {
    transform: translateY(-1.1rem) scale(0.85);
    padding: 0 0.2rem;
    color: #3b82f6;
    background: white;
    height: 18px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 1 !important;
}

.form-floating .imask-input {
  background: transparent;
}

.login-button {
  width: 100%;
  padding: 0.875rem;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.login-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.login-button:active {
  transform: translateY(0);
}

.alert {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.alert-danger {
  background: #fee2e2;
  color: #dc2626;
  border: 1px solid #fecaca;
}

.alert-success {
  background: #dcfce7;
  color: #16a34a;
  border: 1px solid #bbf7d0;
}

@media (min-width: 1024px) {
  .login-illustration {
    display: block;
  }
  
  .login-form-container {
    margin: 0;
  }
} 